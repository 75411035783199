import React, { Component } from "react";
import { Collapse, Card, CardHeader, CardBody } from "reactstrap";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      faqs: [
        {
          id: 0,
          collapse: 0,
          question: "How to get Party Wildlife?",
          answer:
            "Mint one here on our website. If they are sold out, you can check on OpenSea.",
        },
        {
          id: 1,
          collapse: 0,
          question: "How to mint?",
          answer:
            "Download Metamask extension for Google Chrome. Buy Ethereum (ETH) on an exchange like Coinbase, Binance, PayPal, etc and send it to your Metamask ETH Wallet Public Address. Connect your Metamask Wallet to our website by clicking on the Chrome extension icon at the top-right of the browser (it looks like a Fox) while on our website. Click “Mint” on our website and approve the transaction on Metamask.",
        },
        {
          id: 2,
          collapse: 0,
          question: "How to see my NFTs after minting?",
          answer:
            "After minting, you can go to Opensea and connect your MetaMask wallet. You will see the newy minted Party Wildlife NFTs under 'My Profile' -> 'Collected'. It is possible that OpenSea has delays with updating - Wait a few minutes and refresh the OpenSea page.",
        },
        {
          id: 3,
          collapse: 0,
          question:
            "Why do I see a tent image instead of Party Wildlife characters?",
          answer:
            "Before the reveal date, your Party Wildlife characters are sleeping inside their tents. They will wake up and be ready to party on reveal date!",
        },

        {
          id: 4,
          collapse: 0,
          question: "When is the reveal date?",
          answer:
            "Sept 2021. Please join our Discord community for the latest updates.",
        },
        {
          id: 5,
          collapse: 0,
          question: "What do I actually own?",
          answer:
            "The token owner has full copywrite of the character avatar. In addition, owners have opportunities to collect and own more animation assets and participate in the animation creation process.",
        },
        {
          id: 6,
          collapse: 0,
          question: "How to participate in the creation process?",
          answer:
            "Please join our Discord community and participate in the creation process through community events there. Please keep an eye on our official events.",
        },
        {
          id: 7,
          collapse: 0,
          question: "Why do I see a question mark image?",
          answer:
            "If you see a question mark on OpenSea, on the page of your NFT, please click the button 'Refresh Metadata'. In a few minutes, you can refresh the page and check for updates.",
        },
      ],
    };
  }

  toggle(e) {
    let event = e.target.dataset.event;
    let faqs = this.state.faqs;
    faqs[event].collapse = faqs[event].collapse === 0 ? 1 : 0;
    this.setState({ faq: faqs });
  }

  render() {
    const { faqs } = this.state;
    return (
      <div>
        {faqs.map((faq) => {
          return (
            <Card style={{ marginBottom: "1rem" }} key={faq.id}>
              <CardHeader onClick={this.toggle} data-event={faq.id}>
                {faq.question} {faq.collapse === 1 ? "-" : "+"}
              </CardHeader>
              <Collapse isOpen={faq.collapse === 1}>
                <CardBody>{faq.answer}</CardBody>
              </Collapse>
            </Card>
          );
        })}
      </div>
    );
  }
}

export default Faq;
