import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/partywildlife.css";
import "assets/scss/blk-design-system-react.scss?v=1.2.0";

import Index from "views/Index.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" render={(props) => <Index {...props} />} />
      {/* <Redirect from="/" to="/home" /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
