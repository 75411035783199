/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default function Footer() {
  return (
    <footer className="party-footer">
      <Container>
        <Row>
          <Col md="6">
            <Button className="btn-link" color="info" href="#partytop">
              About
            </Button>
            <Button className="btn-link" color="success" href="#partyroadmap">
              Roadmap
            </Button>
            <Button className="btn-link" color="warning" href="#partymint">
              Buy
            </Button>
          </Col>

          <Col md="3">
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://discord.gg/BxfUv8s2AG"
                target="_blank"
              >
                <i className="fab fa-discord" />
              </Button>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://twitter.com/partywildlifeNF"
                target="_blank"
              >
                <i className="fab fa-twitter" />
              </Button>
            </div>
          </Col>
          <Col md="3">
            <div className="footer-text align-items-center">
              {"\u00A9"} 2021 Party Wildlife Studio
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
