import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

// sections for this page/view
import Faq from "views/PartySections/Faq.js";

export default function About() {
  React.useEffect(() => {}, []);
  return (
    <div>
      {/* ======Start of Intro===================== */}
      <section id="partytop" className="section-about section section-lg">
        <div className="page-header header-filter">
          <Container>
            <div className="content-center ">
              <h4>NFT Collectibles + Community Powered Animated Series</h4>
              <h1 className="h1-seo biggest-title lucky-font">
                PARTY WILDLIFE
              </h1>
              <h3 className="txt-below-title">
                11,111 unique wildlife creatures partying on the ETH blockchain.
                A long lasting party that is filled with fun and positivity.
              </h3>
              <h3 className="lucky-font white-text md-4">
                CREATING A MASTERPIECE OF ANIMATED SERIES TOGETHER!
              </h3>

              {/* <Button
                className="btn-round mint-btn-1 text-center align-items-center"
                type="button"
                color="warning"
                href="#partymint"
              >
                <div className="mint-btn-1-txt ml-2 mr-2">MINT </div>
              </Button> */}
              {/* =====start of mint not started========== */}
              <div className="mint-btn-2-txt ml-2 mr-2">
                MINT HAS NOT STARTED
              </div>
              <Button
                id="mint-not-start-btn"
                className="mint-not-start-btn btn-round lucky-font"
                type="button"
                href="https://discord.gg/BxfUv8s2AG"
                target="_blank"
              >
                <div className="">Join Community Discord</div>
              </Button>
              {/* =====end of mint not started========== */}
              <Row className="justify-content-center mt-5">
                <div>
                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://twitter.com/partywildlifeNF"
                    target="_blank"
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://discord.gg/BxfUv8s2AG"
                    target="_blank"
                  >
                    <i className="fab fa-discord" />
                  </Button>
                </div>
              </Row>
              <Row className="justify-content-center">
                <Col md="7">
                  Party Wildlife are NFTs on ETH blockchain. They are
                  programmably generated characters, assembled from more than
                  150 unique traits, including super rare ones.
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
      {/* ======Start of Intro to animation===================== */}
      <section className="section-animation section section-lg">
        <Container>
          <Row className="justify-content-center content-center">
            <h3 className="party-no-margin">Mission</h3>
          </Row>
          <Row className="justify-content-center content-center">
            <div className="party-section-title-lg">ANIMATED SERIES</div>
          </Row>

          <Row className="row-grid justify-content-between mt-5">
            <Col md="4">
              <img
                alt="..."
                className="img-fluid floating"
                src={require("assets/img/partyimg/animal1.jpg").default}
              />
            </Col>
            <Col md="7">
              <div className="">
                <h3 className="mb-3">
                  We are creating animated series that all ages can appreciate.
                  As pround owners of Party Wildlife NFTs, the community has the
                  voting rights on deciding project priorities and key
                  directions! Let's make a masterpiece of animated series
                  together!
                </h3>
                <hr className="party-line" />
                <h3 className="lucky-font mt-2 mb-2">OWNER BENEFITS:</h3>

                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-warning mb-2">
                        <i className="fas fa-paw" />
                      </div>
                      <div className="ml-3">
                        <h4>
                          Own avatars of the characters that will be acting in
                          the show. Gain the full copyright. Free to use,
                          modify, trade, and commercialize.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-warning mb-2">
                        <i className="fas fa-paw" />
                      </div>
                      <div className="ml-3">
                        <h4>
                          Influence the storyline and be part of the creation
                          process.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-warning mb-2">
                        <i className="fas fa-paw" />
                      </div>
                      <div className="ml-3">
                        <h4>
                          Collect assets and episodes of the animated series.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-warning mb-2">
                        <i className="fas fa-paw" />
                      </div>
                      <div className="ml-3">
                        <h4>
                          Be on the acknowledgement list at the end of the show.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ======Start of Why Party Wildlife?===================== */}
      <section className="section-whyparty section section-lg">
        <Container>
          <Row className="justify-content-center">
            <div className="content-center ">
              <h1 className="party-section-title">Why Party Wildlife?</h1>
            </div>
          </Row>
          <Row className="justify-content-center">
            <Col md="10">
              <Row>
                <Col>
                  <Card className="h-100">
                    <CardHeader>Animation Enthusiasts</CardHeader>
                    <CardBody>
                      We love animations. Not just watching but also
                      enthusiastic about creating and giving life to characters.
                    </CardBody>
                    <CardFooter>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/partyimg/animal4.jpg").default}
                      />
                    </CardFooter>
                  </Card>
                </Col>
                <Col>
                  <Card className="h-100">
                    <CardHeader>Community Builders</CardHeader>
                    <CardBody>
                      Build community and long lasting bond via the things and
                      feelings we care most about. Create enjoyable and
                      memerable experiences together.
                    </CardBody>
                    <CardFooter>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/partyimg/animal2.jpg").default}
                      />
                    </CardFooter>
                  </Card>
                </Col>
                <Col>
                  <Card className="h-100">
                    <CardHeader>Wildlife Lovers</CardHeader>
                    <CardBody>
                      We love our nature, animals and especially wildlives. And
                      take actions to protect them, the nature and our planet.
                    </CardBody>
                    <CardFooter>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/partyimg/animal3.jpg").default}
                      />
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* =========Rarity================== */}
      <section className="section-rarity section section-lg">
        <Container>
          <Row className="justify-content-center">
            <div className="content-center ">
              <h1 className="party-section-title">Rarity</h1>
              <hr className="party-line-center" />
            </div>
          </Row>

          <Row className="justify-content-center">
            <Col md="8 text-center">
              <h4 className="d-none d-sm-block">
                Each unique party wildlife is minted with traits of different
                rareties.
              </h4>

              <h3 className="md-2">SHHH……</h3>
              <h4>
                Before the reveal date, our party wildlives will be sleeping in
                their tents, waiting for the party to start. There are three
                different tents with varying rarities. Rare wildlives love to
                sleep in rare tents - look out for rare creatures waking up from
                tents that are decorated with feathers.
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="10">
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={
                          require("assets/img/partyimg/Tent_Move_1sm.gif")
                            .default
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <h2 className="text-center lucky-font">Straw Tent</h2>
                      <h4 className="text-center">Normal</h4>
                      <h4 className="text-center">80% have this trait</h4>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={
                          require("assets/img/partyimg/Tent_Move_2sm.gif")
                            .default
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <h2 className="text-center lucky-font">Canvas Tent</h2>
                      <h4 className="text-center">Rare</h4>
                      <h4 className="text-center">16% have this trait</h4>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={
                          require("assets/img/partyimg/Tent_Move_3sm.gif")
                            .default
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <h2 className="text-center lucky-font">Feather Tent</h2>
                      <h4 className="text-center">Super Rare</h4>
                      <h4 className="text-center">4% have this trait</h4>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* =======Start of Roadmap==================== */}
      <section id="partyroadmap" className="section-roadmap section section-lg">
        <Container>
          <Row className="justify-content-center">
            <div className="text-center">
              <h1 className="party-section-title">ROADMAP</h1>

              <h4>Solid • Clear • Deliverable</h4>
              <hr className="party-line" />
            </div>
          </Row>
          <Row>
            <Col md="2" />
            <Col md="10">
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  MINT START
                </Col>
                <Col md="8" className="roadmap-task">
                  Mint your Party Wildlife. Your wildlife will be hiding in it’s
                  tent, waiting to be waken up at the reveal event.
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  REVEAL
                </Col>
                <Col md="8" className="roadmap-task">
                  Wildlifes come out of their tents! The party begins!
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  25% SELL OUT
                </Col>
                <Col md="8" className="roadmap-task">
                  Giveaways to the community
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  50% SELL OUT
                </Col>
                <Col md="8" className="roadmap-task">
                  Launch a set of animated emotes of Party Wildlife characters
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  75% SELL OUT
                </Col>
                <Col md="8" className="roadmap-task">
                  Charitiy donation choosen by the community on wildlives
                  protection
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  90% SELL OUT
                </Col>
                <Col md="8" className="roadmap-task">
                  Merch store and swags of Party Wildlife
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  100% SELL OUT
                </Col>
                <Col md="8" className="roadmap-task">
                  Create first episode of Party Wildlife animation series
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  Collect
                </Col>
                <Col md="8" className="roadmap-task">
                  Owners will be able to mint assets from the show, and get a
                  chance to be have your characters appear in the animated
                  series.
                </Col>
              </Row>
              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  Watch
                </Col>
                <Col md="8" className="roadmap-task">
                  Premier the first episode of Party Wildlife together with our
                  community, and you will have a chance to see your characters
                  in it.
                </Col>
              </Row>

              <Row className="roadmap-item align-items-center">
                <Col md="2" className="roadmap-milestone">
                  More
                </Col>
                <Col md="8" className="roadmap-task">
                  More Episodes and Games!
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="party-roadmap"></div>
        </Container>
      </section>
      {/* =======Start of Team==================== */}
      <section className="section-team section section-lg">
        <Container>
          <Row className="justify-content-center">
            <div className="content-center">
              <h1 className="party-section-title">MEET THE TEAM</h1>
            </div>
          </Row>
          <Row>
            <Col className="text-center mt-5 mt-sm-0" sm="3" xs="6">
              <img
                alt="..."
                className="team1 img-fluid rounded-circle shadow-lg"
                src={require("assets/img/partyimg/team1.png").default}
                style={{ width: "150px" }}
              />
              <h4 className="d-block text-uppercase font-weight-bold mb-4">
                Flawless Artist
              </h4>
            </Col>
            <Col className="text-center mt-5 mt-sm-0" sm="3" xs="6">
              <img
                alt="..."
                className="team2 img-fluid rounded-circle shadow-lg"
                src={require("assets/img/partyimg/team2.png").default}
                style={{ width: "150px" }}
              />
              <h4 className="d-block text-uppercase font-weight-bold mb-4">
                Super Dev
              </h4>
            </Col>
            <Col className="text-center mt-5 mt-sm-0" sm="3" xs="6">
              <img
                alt="..."
                className="team3 img-fluid rounded-circle shadow-lg"
                src={require("assets/img/partyimg/team3.png").default}
                style={{ width: "150px" }}
              />
              <h4 className="d-block text-uppercase font-weight-bold mb-4">
                Mighty Operation
              </h4>
            </Col>
            <Col className="text-center mt-5 mt-sm-0" sm="3" xs="6">
              <img
                alt="..."
                className="team4 img-fluid rounded-circle shadow-lg"
                src={require("assets/img/partyimg/team4.png").default}
                style={{ width: "150px" }}
              />
              <h4 className="d-block text-uppercase font-weight-bold mb-4">
                Dynamic Animatior
              </h4>
            </Col>
          </Row>
        </Container>
        {/* =========Contract================== */}
        {/* <Container className="mt-5">
          <Row className="justify-content-center text-center">
            <Col md="10">
              <div className="text-center contract-box pt-3 pb-3">
                CONTRACT: 12345678901234567890
              </div>
            </Col>
          </Row>
        </Container> */}
      </section>

      {/* ======FAQs===================== */}
      <section className="section-faq section section-lg">
        <Container>
          <Row className="justify-content-center text-center">
            <h1 className="party-section-title">FAQs</h1>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <Faq />
            </Col>
          </Row>
        </Container>
      </section>

      {/* ======Join Discord===================== */}
      <section id="partymint" className="section-social section section-lg">
        <Container>
          <Row className="justify-content-center">
            <h3 className="coming-soon-txt">
              Sale has not started. Coming soon in Sept. 2021. Join discord to
              get updates.
            </h3>
          </Row>
          <Row className="justify-content-center ">
            <Button
              id="party-join-discord-btn"
              className="party-join-discord-btn btn-round lucky-font "
              type="button"
              href="https://discord.gg/BxfUv8s2AG"
              target="_blank"
            >
              <div className="ml-2 mr-2 ">Join Community Discord</div>
            </Button>
          </Row>
          <hr />
        </Container>
      </section>
    </div>
  );
}
