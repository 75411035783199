/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactGA from "react-ga";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import About from "views/PartySections/About.js";

import MintSection from "./PartySections/MintSection";

export default function Index() {
  React.useEffect(() => {
    ReactGA.initialize("UA-207174171-1"); // GA Universal Analytics ID.
    // To report page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main">
          <About />
          {/* <MintSection /> */}
        </div>
        <Footer />
      </div>
    </>
  );
}
